import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

interface HeaderProps {
  siteTitle: string;
}

const NavBarContainer = styled.div`
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-bottom: 16px;
`;
const NavBar = styled.div`
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.0875rem;
`;

const NavLink = styled(Link)`
  margin-right: 16px;
`;

const Header = ({ siteTitle }: HeaderProps) => (
  <React.Fragment>
    <header
      style={{
        background: `white`,
      }}
    >
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `1.45rem 1.0875rem`,
        }}
      >
        <h1 style={{ margin: 0 }}>
          <Link
            to="/"
            style={{
              color: `black`,
              textDecoration: `none`,
            }}
          >
            {siteTitle}
          </Link>
        </h1>
      </div>
    </header>
    <NavBarContainer>
      <NavBar>
        <NavLink to="/about">About</NavLink>
        <NavLink to="/programming-projects">Programming Projects</NavLink>
        <NavLink to="/contact">Contact</NavLink>
      </NavBar>
    </NavBarContainer>
  </React.Fragment>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
